import { styled } from 'office-ui-fabric-react';

import { getNavLinkGroupStyles } from './NavLinkGroup.styles';
import { INavLinkGroupProps, INavLinkGroupStyleProps, INavLinkGroupStyles } from './NavLinkGroup.types';
import { NavLinkGroupBase } from './NavLinkGroupBase';

export const NavLinkGroup: React.FunctionComponent<INavLinkGroupProps> = styled<
  INavLinkGroupProps,
  INavLinkGroupStyleProps,
  INavLinkGroupStyles
>(NavLinkGroupBase, getNavLinkGroupStyles, undefined, { scope: 'NavLinkGroup' });
