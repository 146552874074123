import * as React from 'react';
import {
  classNamesFunction,
  Icon,
  anchorProperties,
  buttonProperties,
  getNativeProps,
  mergeStyles,
  getId,
  TooltipHost,
  DirectionalHint,
  styled,
  ITooltipHostProps,
  ITooltipStyleProps,
  ITooltipHostStyles
} from 'office-ui-fabric-react';
import { INavLinkStyles, INavLinkProps, INavLinkState, INavLinkStyleProps } from './NavLink.types';
import { NavTeachingBubble } from './NavTeachingBubble/NavTeachingBubble';

const getClassNames = classNamesFunction<INavLinkStyleProps, INavLinkStyles>();

/** This fix is related to a flex bug in IE11 that prevents the browser from calculating proper height in flex
 * children. The work around is to wrap the item in another flex container which this does by restyling
 * the TooltipHost we use within this component. As soon as IE11 support is dropped we can remove this
 * https://github.com/philipwalton/flexbugs#flexbug-3
 */
const FlexTooltipHost = styled<ITooltipHostProps, ITooltipStyleProps, ITooltipHostStyles>(
  TooltipHost,
  { root: { display: 'flex' } },
  undefined,
  { scope: 'FlexTooltipHost' }
);

export class NavLinkBase extends React.Component<INavLinkProps, INavLinkState> {
  private _linkTipId: string = getId('linkTip');
  public constructor(props: INavLinkProps) {
    super(props);
    this.state = { linkRef: null };
  }
  public render(): JSX.Element {
    const {
      name,
      hasNestedMenu,
      isNested,
      isNavCollapsed,
      isExpanded,
      isSelected,
      hasSelectedNestedLink,
      styles,
      primaryIconName,
      href,
      forceAnchor,
      theme,
      showTeachingBubble,
      teachingBubbleProps,
      isParentExpanded,
      preserveIconSpace,
      tooltipProps,
      // tslint:disable-next-line:deprecation
      ariaLabel
    } = this.props;
    const classNames = getClassNames(styles!, {
      theme: theme!,
      isNavCollapsed: isNavCollapsed!,
      hasNestedMenu: hasNestedMenu!,
      isExpanded: isExpanded!,
      isSelected: isSelected!,
      hasSelectedNestedLink: hasSelectedNestedLink!,
      isNested: isNested!,
      hasIcon: !!primaryIconName,
      preserveIconSpace: preserveIconSpace!
    });
    const { className, title, disabled, ...nativeProps } = getNativeProps(
      this.props,
      href ? anchorProperties : buttonProperties
    );

    let iconName = undefined;
    if (hasNestedMenu) {
      iconName = 'ChevronDownMed';
    }

    const navContent: JSX.Element = (
      <>
        <div className={classNames.iconContainer} role="presentation">
          {primaryIconName && <Icon iconName={primaryIconName} className={classNames.icon} />}
        </div>
        <div className={classNames.text} tabIndex={-1} role="presentation" data-is-focusable="false">
          {name}
        </div>
        {!(isNavCollapsed && !isNested) && (
          <Icon iconName={iconName} className={classNames.secondaryIcon} role="presentation" />
        )}
      </>
    );

    const rootStyle = mergeStyles(classNames.root, className);

    const linkBase =
      !href && !forceAnchor ? (
        <button
          aria-label={ariaLabel}
          {...nativeProps}
          onClick={this._onClick}
          className={rootStyle}
          ref={this._setLinkRef}
        >
          {navContent}
        </button>
      ) : (
        <a aria-label={ariaLabel} {...nativeProps} onClick={this._onClick} className={rootStyle} ref={this._setLinkRef}>
          {navContent}
        </a>
      );

    // it wouldn't make sense to show a call out on a nested nav item that isn't visible or transient
    const shouldTeachingBubbleShow =
      showTeachingBubble && (!isNested || (!isNavCollapsed && isNested && isParentExpanded));

    return (
      <>
        {disabled ? (
          linkBase
        ) : (
          <FlexTooltipHost
            id={this._linkTipId}
            content={title || (isNavCollapsed && !isNested && name)}
            directionalHint={DirectionalHint.rightCenter}
            {...tooltipProps}
          >
            {linkBase}
          </FlexTooltipHost>
        )}
        {shouldTeachingBubbleShow && <NavTeachingBubble target={this.state.linkRef} {...teachingBubbleProps} />}
      </>
    );
  }

  private _onClick = (ev: React.MouseEvent<HTMLElement>): void => {
    if (this.props.onClick) {
      this.props.onClick(ev, this.props.item);
    }
  };

  private _setLinkRef = (linkItem: HTMLButtonElement | HTMLAnchorElement | null): void => {
    // we do this with state because getting a ref is not synchronous
    // without this, a TeachingBubble will not show if showTeachingBubble is true initially
    if (this.state.linkRef !== linkItem) {
      this.setState({ linkRef: linkItem });
    }
  };
}
