import { INavLinkGroup } from './Nav.types';
import { INavItemCountInfo } from './Nav.utils.types';

/**
 * Function to calculate the number of items and the position of the edit item
 */
export const getNavCountInfo = (
  groups: INavLinkGroup[],
  enableCustomization?: boolean,
  showMore?: boolean,
  collapseLinkHidden?: boolean
): INavItemCountInfo => {
  // The first nav item index should be 1 unless the collapse nav button is hidden in which case it should be 0
  const navGroupStartIndex = collapseLinkHidden ? 0 : 1;
  let navItemTotal: number = navGroupStartIndex;
  groups.forEach((group: INavLinkGroup) => {
    navItemTotal = navItemTotal + group.links.length;
  });

  // this number MUST be maintained manually and should correspond to
  // the number of items rendered below outside the groups array
  navItemTotal += enableCustomization && showMore ? 2 : enableCustomization ? 1 : 0;

  const editNavIndex = enableCustomization ? navItemTotal - (showMore ? 1 : 0) : undefined;
  // We add 1 to navGroupStartIndex to ensure there's a friendly index read by the screen reader (first item is 1 vs 0)
  return { navItemTotal, editNavIndex, navGroupStartIndex: navGroupStartIndex + 1 };
};

/**
 * Function to get the next group count start when provided with an initial count, index and group array
 * @param initialCount the initial count to start the function from. Usually this is the position index of the previous
 * group's last item but could be something else if this is the first group.
 * @param groupIndex the 0 based index of the group count we're calculating
 * @param groups the array of groups
 */
export const getNavGroupCountStart = (initialCount: number, groupIndex: number, groups: INavLinkGroup[]) => {
  return initialCount + (groupIndex > 0 ? groups[groupIndex - 1].links.length : 0);
};
