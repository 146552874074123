import { FontSizes, IStyle, ITheme, ZIndexes } from 'office-ui-fabric-react';
import { throwOnUndefinedColor } from '@m365-admin/customizations';
import { getRTLFlipOptOut } from '@m365-admin/utilities';

import { INavStyleProps, INavStyles } from './Nav.types';

let navScrollBarWidth: number = 0;
let currentZoomLevel: number = 0;
const navDividerHeight = 16;

const componentName: string = 'Nav';

function getScrollBarWidth(nextZoomLevel: number): number {
  if (navScrollBarWidth !== 0 && currentZoomLevel === nextZoomLevel) {
    return navScrollBarWidth;
  }
  currentZoomLevel = nextZoomLevel;
  // Get the browser scrollbar width because they're all different
  const scrollDiv: HTMLDivElement = document.createElement('div');
  scrollDiv.setAttribute('style', 'width: 100px;height: 100px;overflow: scroll;position: absolute;top: -999px;');
  const contentDiv: HTMLElement = document.createElement('p');
  contentDiv.setAttribute('style', 'width: 100px;height: 200px;');
  scrollDiv.appendChild(contentDiv);
  document.body.appendChild(scrollDiv);
  navScrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  return navScrollBarWidth;
}

const navFontSize = FontSizes.medium;
const navWidth = 280;

export const navCollapsedWidth = 48;

export const navItemHeight = 40;

export const navGroupDividerStyle = (theme: ITheme): IStyle => {
  return {
    display: 'block',
    position: 'relative',
    height: navDividerHeight,
    textAlign: 'center',
    '::after': {
      content: '" "',
      width: 'calc(100% - 32px)',
      position: 'absolute',
      height: '1px',
      top: 10,
      left: '16px',
      backgroundColor: theme.palette.neutralTertiaryAlt
    }
  };
};

export const getNavStyles = (props: INavStyleProps): INavStyles => {
  const { isNavCollapsed, shouldScroll, zoomLevel, theme } = props;

  const scrollBarWidth: number = getScrollBarWidth(zoomLevel);

  return {
    root: {
      position: 'relative',
      zIndex: ZIndexes.Nav,
      flex: '0 0 auto'
    },
    navWrapper: [
      {
        overflow: 'hidden',
        height: '100%'
      },
      shouldScroll && {
        ':hover': {
          overflow: 'unset',
          marginTop: 0,
          marginRight: `-${scrollBarWidth}px`,
          marginBottom: 0,
          marginLeft: '0px'
        },
        '*[dir="rtl"] &:hover': {
          marginRight: `0px  ${getRTLFlipOptOut()}`,
          marginLeft: `-${scrollBarWidth}px  ${getRTLFlipOptOut()}`
        }
      }
    ],
    navContainer: [
      {
        width: navWidth + scrollBarWidth,
        backgroundColor: throwOnUndefinedColor(theme.semanticColors.navBackground, 'navBackground', componentName),
        color: theme.semanticColors.buttonText,
        transitionProperty: 'width',
        transitionDuration: '.2s',
        userSelect: 'none',
        fontSize: navFontSize,
        overflowY: 'scroll',
        overflowX: 'hidden',
        marginRight: `-${scrollBarWidth}px`,
        marginLeft: '0px',
        height: '100%',
        '*[dir="rtl"] &': {
          marginRight: `0px ${getRTLFlipOptOut()}`,
          marginLeft: `-${scrollBarWidth}px  ${getRTLFlipOptOut()}`
        }
      },
      isNavCollapsed && {
        width: navCollapsedWidth + scrollBarWidth
      },
      shouldScroll && {
        ':hover': {
          margin: '0px'
        }
      }
    ],
    navGroup: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    },
    navGroupDivider: navGroupDividerStyle(theme)
  };
};
