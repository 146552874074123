import { ITeachingBubbleStyleProps, ITeachingBubbleStyles } from 'office-ui-fabric-react';

export const getNavTeachingBubbleStyles = (_props: ITeachingBubbleStyleProps): Partial<ITeachingBubbleStyles> => {
  return {
    content: {
      animation: 'none'
    },
    subComponentStyles: {
      callout: {
        root: {
          animation: 'none',
          width: '284px',
          maxWidth: '284px'
        }
      }
    },
    footer: {
      selectors: {
        '& *': {
          flex: '1 1 auto'
        },
        '& .ms-Button:not(:first-child)': {
          marginLeft: '16px'
        }
      }
    },
    closeButton: {
      top: '4px',
      right: '4px'
    },
    bodyContent: {
      paddingTop: '18px'
    }
  };
};
