import { FontWeights } from 'office-ui-fabric-react';
import { INavGroupStyles, INavGroupStyleProps } from './NavGroup.types';
import { navItemHeight, navGroupDividerStyle } from './Nav.styles';

export const getNavGroupStyles = (props: INavGroupStyleProps): INavGroupStyles => {
  const { theme } = props;
  return {
    navGroupDivider: navGroupDividerStyle(theme),
    navGroupTitle: {
      lineHeight: navItemHeight,
      color: theme.palette.neutralPrimary,
      fontWeight: FontWeights.semibold,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'flex',
      selectors: {
        '::before': {
          content: '""',
          width: '16px',
          flex: '0 0 auto'
        }
      }
    },
    navItem: {
      margin: '0px',
      padding: '0px'
    }
  };
};
