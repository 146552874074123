import { styled } from 'office-ui-fabric-react';

import { getNavStyles } from './Nav.styles';
import { INavProps, INavStyleProps, INavStyles } from './Nav.types';
import { NavBase } from './NavBase';

export const Nav: React.FunctionComponent<INavProps> = styled<INavProps, INavStyleProps, INavStyles>(
  NavBase,
  getNavStyles,
  undefined,
  {
    scope: 'M365LeftNav'
    // Note the slightly different scope name from component name
    // This one will directly conflict with Fabric Nav otherwise
  }
);
