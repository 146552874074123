import { ITeachingBubbleStyleProps, ITeachingBubbleStyles, styled } from 'office-ui-fabric-react';

import { getNavTeachingBubbleStyles } from './NavTeachingBubble.styles';
import { INavTeachingBubbleProps } from './NavTeachingBubble.types';
import { NavTeachingBubbleBase } from './NavTeachingBubbleBase';

export const NavTeachingBubble: React.FunctionComponent<INavTeachingBubbleProps> = styled<
  INavTeachingBubbleProps,
  ITeachingBubbleStyleProps,
  ITeachingBubbleStyles
>(NavTeachingBubbleBase, getNavTeachingBubbleStyles, undefined, { scope: 'NavTeachingBubble' });
