import { styled } from 'office-ui-fabric-react';

import { getNavLinkStyles } from './NavLink.styles';
import { INavLinkProps, INavLinkStyleProps, INavLinkStyles } from './NavLink.types';
import { NavLinkBase } from './NavLinkBase';

export const NavLink: React.FunctionComponent<INavLinkProps> = styled<
  INavLinkProps,
  INavLinkStyleProps,
  INavLinkStyles
>(NavLinkBase, getNavLinkStyles, undefined, { scope: 'NavLink' }, true);
